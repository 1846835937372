import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Raise Your Support CRM</h1>
      <p>A VenHuizen Family Ministry</p>
    </div>
  );
}

export default App;
